<template>
    <div class="detail">
        <div class="top-head">
            <div class="return" @click="$router.go(-1)"><img src="@/assets/images/return.png"></div>
            <div class="text-center">订单详情</div>
        </div>
        <ul class="course-list courselist" v-if="orderDetail.video">
            <li>
                <div class="buy">
                    已购买
                </div>
                <img :src="domainUrl+orderDetail.video.photo">
                <div class="info">
                    <div class="title ell">{{orderDetail.video.class_name}}</div>
                    <div class="text ell">
                        <span>{{$route.query.count}}讲</span>
                        {{orderDetail.video.feature}}
                    </div>
                    <div class="detail">
                        ¥{{orderDetail.video.money}}
                    </div>
                    <div class="realy">实付款：¥{{orderDetail.actual_moeny}}</div>
                </div>
            </li>
        </ul>
        <dl class="info">
            <dt>订单信息</dt>
            <dd>
                订单编号：{{orderDetail.order_number}} <br>
                支付方式： {{payName}}<br>
                下单时间：{{formatTime(orderDetail.create_time)}}
            </dd>
        </dl>
    </div>
</template>
<script>
import Vue from "vue";
export default {
    data() {
        return {
           orderDetail:[],
           payList: [
                { value: 0, label: '线下付费' },
                { value: 1, label: '支付宝付费' },
                { value: 2, label: '微信付费' }
            ],
            payName:''
        }
    },
    created() {
        this.getDetail(); 
    },
    methods: {
        formatTime(time) {
            //shijianchuo是整数，否则要parseInt转换
            var time = new Date(Number(time+"000"));
            var y = time.getFullYear();
            var m = time.getMonth() + 1;
            var d = time.getDate();
            var h = time.getHours();
            var mm = time.getMinutes();
            var s = time.getSeconds();
            return y + '-' + this.addZero(m) + '-' + this.addZero(d) + ' ' + this.addZero(h) + ':' + this.addZero(mm) + ':' + this.addZero(s);
        },
        addZero(i) {
            return i < 10 ? "0" + i : i + "";
        },
        getDetail() {
            this.$ajax.send({
                url: "/course/order_info",
                data: {order_id:this.$route.query.id},
                type: "post",
                success: data => {
                    if (!!data && data.code == "1001") {                        
                        this.orderDetail = data.data;
                        this.payList.forEach(element => {
                            if(element.value==data.data.pay_type){
                                this.payName=element.label;
                            }
                        });
                    }
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.course-list {
    padding: 88px 0 0 0;
    li {
        border-bottom: 16px solid #f9f9f9;
        padding: 24px 32px;
        margin: 0;
        .buy {
            line-height: 36px;
            margin-bottom: 16px;
            font-weight: bold;
            font-size: 26px;
        }
        .detail {
            border-bottom: 1px solid #f1f2f3;
            font-size: 32px;
            text-align: right;
            font-weight: bold;
            color: #353535;
            padding-bottom: 22px;
        }
        .realy {
            font-size: 32px;
            text-align: right;
            font-weight: bold;
            color: #353535;
            padding-top: 22px;
        }
    }
}
.info {
    padding: 32px;
    dt {
        font-size: 30px;
        padding: 0 0 20px 10px;
        margin-bottom: 24px;
        border-bottom: 1px solid  #F1F2F3;
        font-weight: bold;
    }
    dd {
        line-height: 58px;
        font-size: 30px;
        padding: 0 10px;
    }
}
</style>